@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/gridle";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
.products-filters {
	$this: &;

	z-index: 2;
	margin-top: 8px;

	@include gridle_state(sm) {
		margin-top: 0;
	}

	&__container {
		position: relative;

		@include gridle_state(md) {
			@include container;
		}
	}

	&--sticky {
		position: sticky;
		top: -1px;

		&::before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 67px;
			background: rgba(255, 255, 255, 0.5);
			backdrop-filter: blur(10px);
			z-index: -1;
			opacity: 0;
			transition: all 0.4s linear;
			box-shadow: $common-box-shadow;
			border-bottom: 1px solid $grey-medium;

			@include gridle_state(md) {
				height: 94px;
			}
		}

		&#{$this}--stuck {
			padding-top: 16px;
			padding-bottom: 16px;
			z-index: 2; // 5 to be over the push notification
			background: transparent;

			@include gridle_state(md) {
				padding-top: 24px;
				padding-bottom: 24px;
			}

			&::before {
				opacity: 1;
			}

			#{$this}__content {
				max-height: calc(100vh - 94px); // 94px = height filter in sticky

				&--destination {
					max-height: 478px;
				}
			}
		}
	}

	&__headers {
		display: flex;
		align-items: flex-end;
		border-bottom: none;
		margin-bottom: 0;

		&-title {
			@extend %text-bold;

			position: absolute;
			top: 0;
			left: 50%;
			transform: translateX(-50%);
			margin-bottom: 8px;
			font-size: 1rem;
			line-height: 140%;
			text-align: center;
		}
	}

	&__header {
		flex: 1 1 0;
		width: 0; // https://stackoverflow.com/a/47323475
		position: relative;
		height: 35px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-right: 5px;
		padding: 8px;
		text-align: center;
		text-transform: uppercase;
		font-size: 1.2rem;
		cursor: pointer;
		user-select: none;
		background: white;
		border: 1px solid $grey-medium;
		border-radius: $border-radius-small;
		box-shadow: $common-box-shadow;
		box-sizing: border-box;

		@include gridle_state(md) {
			padding: 8px 16px;
			margin-right: 10px;
			height: 50px;
			font-size: 1.8rem;
		}

		span {
			flex: 1 1 0%;
		}

		.icon {
			height: 10px;
			width: 10px;

			@include gridle_state(md) {
				height: 15px;
				width: 25px;
			}
		}

		.icon--circle-cross-grey {
			height: 20px;
			width: 20px;

			@include gridle_state(md) {
				height: 25px;
				width: 25px;
			}
		}

		&[aria-selected="false"]:focus {
			outline: none;
			box-shadow: none;
			border-color: transparent;

			&::after {
				display: none;
			}
		}

		&:last-of-type {
			margin-right: 0;
		}

		&--active {
			background: var(--color-active, $active-color);
			color: white;
			border-color: transparent;

			#{$this}__filter-icon,
			#{$this}__filter-control,
			#{$this}__filter-name {
				color: white;
			}
		}

		&.react-tabs__tab--selected {
			background: var(--color-active, $active-color);
			color: white;
			border-color: var(--color-active, $active-color);

			&::after {
				content: "";
				position: absolute;
				top: 34px;
				right: 0;
				left: 0;
				margin: 0 auto;
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 12.5px 12.5px 0;
				border-color: var(--color-active, $active-color) transparent transparent transparent;
				background: transparent;

				@include gridle_state(md) {
					top: 44px;
				}
			}

			#{$this}__filter-icon,
			#{$this}__filter-control,
			#{$this}__filter-name {
				color: white;
			}
		}

		&-content {
			display: flex;
			align-items: center;
			width: 100%;
		}
	}

	&__filter {
		&-icon {
			width: 16px;
			height: 16px;

			@include gridle_state(md) {
				width: 30px;
				height: 30px;
			}
		}

		&-name {
			@extend %text-bold;

			margin-left: 6px;
			width: calc(100% - 20px);
			text-overflow: ellipsis;
			white-space: nowrap;
			overflow: hidden;
			text-align: left;
			text-transform: initial;
			font-size: 1.2rem;
			line-height: 120%;

			@include gridle_state(md) {
				margin: 0 10px;
				flex: 1 1 100%;
				font-size: 1.8rem;
				width: auto;
			}

			> div {
				text-overflow: ellipsis;
				white-space: nowrap;
				overflow: hidden;
			}
		}

		&-control {
			display: none;
			font-size: 1.2rem;
			line-height: 140%;
			text-transform: capitalize;
			color: var(--color-active, $active-color);

			@include gridle_state(md) {
				display: flex;
				align-items: center;
			}

			&--active {
				display: flex;
				align-items: center;
				height: 36px;
				width: 20px;

				@include gridle_state(md) {
					height: unset;
					width: unset;
				}
			}

			svg,
			.icon {
				margin-left: 4px;
				width: 12px;
				height: 12px;
			}
		}
	}

	&__content {
		position: absolute;
		right: 0;
		left: 0;
		top: 50px;
		padding: 30px 20px 20px;
		box-sizing: border-box;
		background: white;
		border-radius: $border-radius-small;
		border: 1px solid $grey-medium;
		height: 490px;
		z-index: 3;
		overflow: auto;
		box-shadow: 0 4px 16px rgba(0, 0, 0, 0.08);

		@include gridle_state(md) {
			top: 59px;
			padding: 30px 25px 20px;
			width: 319.33px;
			height: auto;
		}

		&--destination {
			max-height: 478px; // hauteur pour afficher maximum 15 éléments avant de pouvoir scrolle le reste

			.products-filters__facets {
				@include gridle_state(md) {
					display: grid;
					grid-template-columns: auto auto auto;
				}
			}

			.products-filters__facet {
				width: 100%;
			}

			@include gridle_state(sm) {
				width: 680px;
				right: auto;

				.products-filters__facet {
					width: 180px;
					display: inline-flex;
				}
			}
		}

		&--topic {
			.products-filters__facet {
				width: 100%;

				+ .separator {
					margin-top: 10px;
					margin-bottom: 15px;

					@include gridle_state(md) {
						margin-bottom: 10px;
					}
				}
			}

			@include gridle_state(sm) {
				margin: 0 auto;
				width: 332px;

				.products-filters__facet {
					width: 100%;
				}
			}

			@include gridle_state(md) {
				margin-left: 346px;
			}

			&--pos-1 {
				@include gridle_state(md) {
					margin-left: 95px;
				}
			}
		}

		&--period {
			@include gridle_state(sm) {
				left: auto;
				width: 550px;
			}
		}
	}

	&__facets {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;

		.separator {
			margin-top: 10px;
			margin-bottom: 10px;
		}
	}

	&__facet {
		display: flex;
		justify-content: space-between;
		color: $grey-medium;
		margin-bottom: 10px;
		pointer-events: none;

		+ .separator {
			margin-top: 0;
		}

		&:last-of-type {
			margin-bottom: 0;
		}

		&--available {
			color: $black;
			cursor: pointer;
			pointer-events: auto;
		}

		&--active {
			@extend %text-bold;

			color: var(--color-active, $active-color);
			text-decoration: underline;
		}

		@include gridle_state(md) {
			&--available {
				&:hover {
					color: var(--color-active, $active-color);
					text-decoration: underline;
				}
			}
		}
	}

	&__title {
		@extend %text-bold;

		font-size: 1.2rem;
		color: $black;
		margin-bottom: 10px;
		text-transform: uppercase;

		@include gridle_state(md) {
			margin-bottom: 20px;
			font-size: 1.4rem;
		}
	}

	&__months {
		margin-bottom: 37px;

		@include gridle_state(sm) {
			margin-bottom: 0;
			margin-right: 35px;
		}
	}

	&__months {
		flex: 1 1 100%;

		@include gridle_state(sm) {
			flex: auto;
		}
	}
}
