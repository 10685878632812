@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/gridle";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
$smartdp-search-form-image-filter: rgba(0, 0, 0, 0.3) !default;

.media-carousel-item {
	position: relative;
	height: 100%;
	box-shadow: $common-box-shadow;
	box-sizing: border-box;
	border-radius: $border-radius-big;

	&__footer {
		z-index: 2;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		position: absolute;
		bottom: 0;
		box-sizing: border-box;
		width: 100%;
		padding: 8px 12px;
		border-radius: $border-radius-big;

		@include gridle_state(md) {
			height: 55px;
			padding: 16px 24px;
		}
	}

	&__footer:has(.media-carousel-item__copyright) {
		background: linear-gradient(0deg, #000, #0000);
	}

	&__title {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		color: white;
	}

	&__copyright {
		color: white;
		text-shadow: 0 4px 3px #000;
	}

	&__photo {
		display: block;
		padding: 0;
		height: 100%;
		width: 100%;
		cursor: pointer;

		img {
			border-radius: $border-radius-big;
		}

		&::after {
			content: "";
			background: $smartdp-search-form-image-filter;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			border-radius: $border-radius-big;
		}
	}
}
