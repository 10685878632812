@keyframes bounce {
	from {
		transform: translateY(0);
	}

	to {
		transform: translateY(-20px);
	}
}

@keyframes alphaBounce {
	0% {
		transform: translateY(-100%);
		opacity: 0;
	}

	5% {
		transform: translateY(-100%);
		opacity: 0;
	}

	15% {
		transform: translateY(0);
		padding-bottom: 5px;
	}

	30% {
		transform: translateY(-50%);
	}

	40% {
		transform: translateY(0%);
		padding-bottom: 6px;
	}

	50% {
		transform: translateY(-30%);
	}

	70% {
		transform: translateY(0%);
		padding-bottom: 7px;
	}

	80% {
		transform: translateY(-15%);
	}

	90% {
		transform: translateY(0%);
		padding-bottom: 8px;
	}

	95% {
		transform: translateY(-7%);
	}

	97% {
		transform: translateY(0%);
		padding-bottom: 9px;
	}

	99% {
		transform: translateY(-3%);
	}

	100% {
		transform: translateY(0);
		padding-bottom: 9px;
		opacity: 1;
	}
}

@keyframes bounce_horizontal-dot-loader {
	0% {
	}

	50% {
		background-color: white;
	}

	100% {
	}
}

@keyframes shake {
	0% {
		transform: translateX(-5px);
	}

	2% {
		transform: translateX(5px);
	}

	4% {
		transform: translateX(-8px);
	}

	6% {
		transform: translateX(8px);
	}

	8% {
		transform: translateX(-10px);
	}

	10% {
		transform: translateX(10px);
	}

	12% {
		transform: translateX(-8px);
	}

	14% {
		transform: translateX(8px);
	}

	16% {
		transform: translateX(-2px);
	}

	18% {
		transform: translateX(-2px);
	}

	20% {
		transform: translateX(0px);
	}
}
