@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/gridle";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
$bar-height: 17px;

.tripadvisor-review-rating-count {
	display: flex;
	flex-direction: column;

	@include gridle_state(md) {
		width: 200px;
	}

	&__vote {
		display: grid;
		grid-template-columns: minmax(30px, max-content) 68px 1fr;
		column-gap: 8px;
		align-items: center;
		margin-bottom: 4px;
		width: 100%;
		height: $bar-height;

		&:last-of-type {
			margin-bottom: 0;
		}
	}

	&__bar {
		width: 68px;
		height: $bar-height;
		background-color: $grey-light;
	}

	&__progress {
		display: inline-block;
		height: $bar-height;
		background-color: #00a680;
	}

	&__total {
		font-size: 1.2rem;
		line-height: 140%;
		color: $black;
	}

	&__status {
		font-size: 1.2rem;
		line-height: 140%;
		color: $black;
	}
}
