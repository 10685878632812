%scrollbar-thin {
	&::-webkit-scrollbar {
		-webkit-appearance: none;
		width: 5px;
	}

	&::-webkit-scrollbar-thumb {
		border-radius: 2px;
		background-color: rgba(0, 0, 0, 0.4);
		-webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.4);
	}
}
