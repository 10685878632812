@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/gridle";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
@import "../../.styles/mixins";

$merchandising-caroussel-width: 100% !default;
$listings-product-width: 496px !default;
$listings-product-spacing: 16px !default;
$listings-product-margin-bottom: 40px !default;

.product-list {
	$column-spacing-mobile: $listings-product-spacing;
	$column-spacing-desktop: $listings-product-spacing * 2;
	$row-spacing-mobile: $listings-product-spacing * 2;
	$row-spacing-desktop: $listings-product-spacing * 2;
	@include product-list-2-columns(
		$column-spacing-mobile,
		$column-spacing-desktop,
		$row-spacing-mobile,
		$row-spacing-desktop,
		$listings-product-width
	);

	padding: 0 0 16px;

	@include gridle_state(md) {
		padding: 0;
	}

	&__item {
		@include product-item-2-columns(".product-list__item");
	}
}
