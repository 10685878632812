@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/gridle";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
$input-border-color: $grey-medium !default;
$button-primary-background-color: $active-color !default;

.auth {
	$this: &;

	position: relative;
	width: 100%;
	max-height: calc(640px - 30px); // height of modal - margin-y
	overflow: auto;
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none;

	&::-webkit-scrollbar {
		display: none;
	}

	@include gridle_state(md) {
		margin: 0;
	}

	.control-group__input {
		@include gridle_state(md) {
			&:focus,
			&:hover {
				border: 2px solid $grey-medium;
			}
		}
	}

	&__dropdown {
		background: white;
		border-radius: $border-radius-big;
		z-index: 11; // 11 pour être au dessus du layer auth
		padding: 16px;
	}

	&__dropdown {
		background: $grey-light;
		border-radius: $border-radius-big;
		z-index: 11; // 11 pour être au dessus du layer auth
		padding: 16px;
	}

	.auth__button {
		text-transform: unset;
		padding: 10px;
	}

	&__success-message {
		flex: 1 1 0%;
	}

	&__loader,
	&__main {
		background: white;
		padding: 15px;
		border-radius: $border-radius-big;
	}

	&__action {
		position: relative;
		margin-top: 25px;

		.popover {
			bottom: 40px;
			margin-right: 0;
			margin-left: 0;
		}
	}

	&__facebook {
		font-size: 1.2rem;
		position: relative;
		white-space: nowrap;

		.icon {
			height: 20px;
			width: 20px;
			margin-right: 8px;
		}
	}

	&__google {
		+ #{$this}__facebook {
			margin-top: 14px;
			height: 44px;
		}

		+ #{$this}__google {
			margin-top: 14px;
			border: 1px solid transparent; // limit flickering
			height: 44px;
		}
	}

	&__credit {
		&-banner {
			@extend %text-bold;

			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;
			margin-bottom: 16px;
			font-size: 1.6rem;
			line-height: 2rem;
			letter-spacing: -0.6px;
			text-transform: uppercase;
		}

		&-icon {
			margin: 0 10px;
			min-width: 26px;
			height: 32px;

			@include gridle_state(md) {
				margin: 0 10px 0 0;
			}
		}
	}

	&__headline {
		width: 100%;
		margin: 0 0 16px;

		&--small {
			@extend %font-normal;

			font-size: 1.2rem;
			margin-top: 10px;
		}
	}

	&__separator {
		font-size: 1.2rem;
		display: flex;
		margin: 24px 0;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		text-transform: uppercase;

		&::before {
			content: "";
			height: 1px;
			background: $black;
			flex: 1 1 0%;
			margin: 0 14px 0 0;
		}

		&::after {
			content: "";
			height: 1px;
			background: $black;
			flex: 1 1 0%;
			margin: 0 0 0 14px;
		}
	}

	&__form {
		.control-group {
			background: white;
			border-radius: $border-radius-small;
			margin-bottom: 16px;
		}

		.password-policy__list--visible {
			margin-bottom: 16px;
		}
	}

	&__accept-cgv {
		box-sizing: border-box;
		height: 40px;
		display: flex;
		align-items: center;
		margin-bottom: 24px;

		@include gridle_state(md) {
			color: inherit;
		}

		.checkbox__icon {
			margin-right: 8px;

			svg {
				cursor: pointer;
				width: 16px;
				height: 16px;
			}
		}

		.checkbox {
			width: 100%; // fix ie 11 deborde du cadre
		}
	}

	&__terms {
		font-size: 1.2rem;
		user-select: none;

		.relative-link {
			font-size: 1.2rem;
			color: $black;
			display: inline;
			text-decoration: underline;
		}
	}

	&__button {
		&--secondary {
			@extend %link;

			text-decoration: underline;
		}
	}

	&__switch {
		padding: 0 0 24px;
		display: flex;
		justify-content: space-between;

		&__item {
			cursor: pointer;
			text-transform: initial;
			height: 25px;

			&::first-letter {
				text-transform: capitalize;
			}

			&--active {
				border-bottom: 3px solid $button-primary-background-color;
			}
		}
	}

	&__forgotten-password {
		box-sizing: border-box;
		display: flex;
		align-items: center;
		height: 40px; // pour éviter que la hauteur du form varie quand on switch entre connexion et inscription
		margin-bottom: 24px;

		@include gridle_state(md) {
			.relative-link {
				&:focus,
				&:hover {
					text-decoration: underline;
				}
			}
		}
	}

	&__intro {
		margin-bottom: 20px;
		text-align: justify;
		display: flex;
		align-items: center;

		&__close {
			margin-left: 15px;

			.icon {
				width: 20px;
				height: 20px;
			}
		}
	}

	&__resetpassword {
		.alert-message--error {
			margin-bottom: 20px;
		}

		.alert-message--success {
			margin-top: 20px;
		}
	}

	&__resetpassword__action {
		.button {
			margin-top: 10px;
		}

		.alert-message--error .alert-message__text {
			display: block;
		}
	}

	&__changepassword__action {
		.popover {
			bottom: 120px;
		}

		.button {
			margin: 10px 0;
		}
	}

	&__footer {
		margin-top: 15px;
	}

	&__countdown {
		display: inline;

		&-item:not(:first-child) {
			margin-left: 5px;
		}
	}

	&__password {
		position: relative;

		.control-group {
			&__input {
				padding-right: 40px;
			}

			&__note {
				position: absolute;
				right: 5px;
				margin: 0;
				top: 50%;
				width: 24px;
				height: 24px;
				transform: translateY(-50%);
				z-index: 1;
				cursor: pointer;

				svg {
					fill: $black;
				}
			}
		}

		&.control-group--disabled {
			opacity: 0.3;
		}
	}

	&__loader {
		position: absolute;
		inset: 0;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		z-index: 2;
	}

	&--verifying {
		#{$this}__main {
			visibility: hidden;
		}
	}

	.form-error-messages {
		margin-bottom: 16px;

		.alert-message {
			font-size: 1.6rem;
			border-radius: $border-radius-small;

			.list__item__content {
				@extend %text-bold;

				color: inherit;
			}
		}
	}
}
