@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/gridle";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
.register-message {
	padding: 20px 20px 0;

	@include gridle_state(md) {
		background: white;
		border-radius: $border-radius-big;
		color: inherit;
		padding: 20px;
		max-width: 307px;
		box-sizing: border-box;
	}

	&__highlight {
		@extend %text-bold;

		color: white;
		@include gridle_state(md) {
			color: $grey-dark;
		}
	}

	&__body,
	&__title {
		margin: 0 0 10px;
		text-align: center;
	}

	&__body {
		color: white;
		overflow-wrap: break-word;

		@include gridle_state(md) {
			color: $grey-dark;
		}

		&--left {
			text-align: left;
		}
	}

	&__title {
		color: white;

		@include gridle_state(md) {
			color: inherit;
		}
	}

	&__link {
		display: inline-block;
		text-decoration: underline;
		color: white;

		@include gridle_state(md) {
			color: $grey-dark;
		}
	}

	.separator {
		margin: 10px auto;
	}

	.button {
		margin: 10px 0;

		@include gridle_state(md) {
			min-width: 267px;
		}
	}
}
