@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/gridle";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
.recover-booking-data-form {
	&__title {
		text-align: center;
	}

	&__form {
		margin-top: 20px;
	}

	&__intro {
		margin-top: 14px;
		text-align: left;
	}

	&__input {
		position: relative;
		margin-bottom: 14px;

		// suppression de la croix de reinit sur IE pour pas empieter sur les pictos
		input {
			&::-ms-clear {
				display: none;
			}

			color: white;
			background-color: rgba(0, 0, 0, 0.15);

			&:-webkit-autofill {
				-webkit-text-fill-color: white;
				-webkit-box-shadow: 0 0 0 1000px rgba(0, 0, 0, 0.3) inset;
				transition: background-color 5000s ease-in-out 0s;
			}
		}

		.control-group {
			&--touched {
				.control-group__label {
					color: white;
				}
			}

			&__label {
				color: white;
			}

			&--error .control-group__input {
				background: rgba(0, 0, 0, 0.15);
			}

			&__input {
				color: white;

				&:focus,
				&:hover {
					border: 1px solid white;
				}
			}
		}

		.icon {
			height: 20px;
			width: 20px;
		}

		.phone-intl-input .PhoneInputCountryIcon--border {
			background-color: transparent;
			border: 0;
		}
	}

	&__button {
		position: relative;

		.popover {
			bottom: 40px;
		}
	}

	&__success {
		height: 225px;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;

		.icon {
			height: 50px;
			width: 50px;
			margin-bottom: 20px;
		}
	}

	&__got-sms-button {
		margin-top: 20px;

		.button {
			padding-right: 20px;
			padding-left: 20px;
		}
	}

	&__popover {
		.Popover-tip {
			fill: white;
			stroke: $grey-light;
			stroke-width: 1px;
			margin-top: -1px;
		}
	}
}
