@font-face {
	font-family: "excellence-in-motion-new-text";
	src: url("/static/font/ExcellenceInMotionNewText/ExcellenceInMotionNewText-Regular.woff2") format("woff2"),
		url("/static/font/ExcellenceInMotionNewText/ExcellenceInMotionNewText-Regular.woff") format("woff");
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "excellence-in-motion-new-text-bold";
	src: url("/static/font/ExcellenceInMotionNewText/ExcellenceInMotionNewText-Bold.woff2") format("woff2"),
		url("/static/font/ExcellenceInMotionNewText/ExcellenceInMotionNewText-Bold.woff") format("woff");
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}
