@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/gridle";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
.sign-in-with-google-button {
	height: 44px; // eviter le saut de ligne

	#google-login-btn {
		height: 44px; // eviter le saut de ligne
	}

	* {
		border: none;
	}
}

// positionner le one tape google par dessus l'icone mon compte du header pour éviter les hovers accidentels
#credential_picker_container {
	top: 0;
	right: 0;
	padding-top: 20px;
	padding-right: 20px;
}
