@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/gridle";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
.footer-logos {
	display: flex;
	justify-content: center;
	align-items: center;
	grid-gap: 30px;

	@include gridle_state(md) {
		justify-content: flex-start;
	}
}
