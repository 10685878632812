@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/gridle";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
.custom-formatted-message {
	&__rendered-message {
		p {
			display: inline;
			@include gridle_state(md) {
				display: flex;
				flex-wrap: wrap;
			}

		}
	}
}