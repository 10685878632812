@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/gridle";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
.shop-switcher-detail-content {
	&__field {
		height: 48px;
		margin-bottom: 24px;
		@include gridle_state(md) {
			width: 280px;
		}

		.advanced-select__select__control--menu-is-open {
			border-bottom-left-radius: 0;
			border-bottom-right-radius: 0;
		}
	}

	&__suggestion {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		height: 24px;
		margin-top: 8px;
		white-space: initial;
		overflow: hidden;
		-webkit-line-clamp: 1;
		-webkit-box-orient: vertical;
		cursor: pointer;
		color: var(--color-black, $black);

		.icon {
			width: 20px;
			height: 20px;
		}

		&-selected {
			.icon {
				position: absolute;
				right: 0;
				top: 0;
			}
		}
	}

	.advanced-select__select {
		&__dropdown-indicator {
			display: flex;
		}

		&__single-value {
			width: 100% !important;
			position: relative;
		}

		&__value-container {
			padding-right: 0;
		}
	}

	.alert-message {
		margin-bottom: 24px;
	}
}
