$booking-bloc-title-main-background-color: $black !default;

@mixin _header {
	display: flex;
	align-items: center;
	justify-content: center;
	background: $booking-bloc-title-main-background-color;
	color: white;
	box-sizing: border-box;
	text-align: center;
}

@mixin expandHeightOnLargeScreen {
	transition: all 0.3s ease;

	@include gridle_state(mc) {
		min-height: 300px;
		height: 300px;
		transition: all 0.3s ease;
	}

	@include gridle_state(sm) {
		min-height: 510px;
		height: 510px;
		transition: all 0.3s ease;
	}

	@include gridle_state(md) {
		min-height: 510px;
		height: 510px;
		transition: all 0.3s ease;
	}

	@include gridle_state(xlg) {
		min-height: 771px;
		height: 771px;
		transition: all 0.3s ease;
	}
}

@mixin animateOnHover {
	cursor: pointer;
	transition: transform 0.3s ease-in-out;

	@include gridle_state(md) {
		&:hover,
		&:focus {
			transform: scale(1.1);
		}
	}
}

@mixin noScrollbars {
	scrollbar-width: thin;
	scrollbar-color: transparent transparent;
	scroll-behavior: smooth;
	transition-delay: 1000ms;

	&::-webkit-scrollbar {
		width: 1px;
	}

	&::-webkit-scrollbar-track {
		background: transparent;
	}

	&::-webkit-scrollbar-thumb {
		background-color: transparent;
	}

	&::-webkit-scrollbar {
		display: none; /* Safari and Chrome */
	}
}

//@mixin product-list-2-columns(
//	$column-spacing-mobile,
//	$column-spacing-desktop,
//	$row-spacing-mobile,
//	$row-spacing-desktop,
//	$product-width
//) {
//	display: grid;
//	grid-template-columns: $column-spacing-mobile calc(100% - ($column-spacing-mobile * 2)) $column-spacing-mobile;
//	grid-row-gap: $row-spacing-mobile;
//
//	@include gridle_state(md) {
//		grid-template-columns: 1fr $product-width $column-spacing-desktop $product-width 1fr;
//		grid-row-gap: $row-spacing-desktop;
//	}
//}

@mixin product-list-2-columns(
	$column-spacing-mobile,
	$column-spacing-desktop,
	$row-spacing-mobile,
	$row-spacing-desktop,
	$product-width
) {
	display: grid;
	grid-template-columns: $column-spacing-mobile calc(100% - ($column-spacing-mobile * 2)) $column-spacing-mobile;
	grid-row-gap: $row-spacing-mobile;

	@include gridle_state(md) {
		grid-template-columns: calc(50% - ($product-width + $column-spacing-desktop/2)) $product-width $column-spacing-desktop $product-width calc(
				50% - ($product-width + $column-spacing-desktop/2)
			);
		grid-row-gap: $row-spacing-desktop;
	}
}

@mixin product-item-2-columns($item-class-name) {
	width: 100%;
	grid-column: 2/3;

	@include gridle_state(md) {
		&:nth-child(odd of #{$item-class-name}) {
			grid-column: 2/3;
		}

		&:nth-child(even of #{$item-class-name}) {
			grid-column: 4/5;
		}
	}
}

@mixin merch-item-2-columns() {
	width: 100%;
	grid-column: 2/3;

	@include gridle_state(md) {
		grid-column: 2/5;
	}
}