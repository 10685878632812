@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/gridle";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
$auth-modal-width: 1024px;
$height: 631px;
$text-shadow-mobile: 0 2px 20px rgba(0, 0, 0, 0.3);
$auth-forgotten-password-margin-bottom: 25px;
$auth-form-text-color-static: $black !default;
$auth-form-background-static: white !default;
$auth-form-box-shadow-static: 0 1px 14px 0 rgba(5, 16, 57, 0.4);
$auth-form-border-color-static: rgba(255, 255, 255, 0.3);

.auth-modal {
	&__header {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
	}

	&__content {
		position: absolute;
		top: calc(70px + 10%);
		left: 50%;
		transform: translate(-50%, 0%);
		width: $auth-modal-width;
		border-radius: $border-radius-big;
		display: flex;
		align-items: center;
		justify-content: space-between;
		max-height: $height;
		padding: 24px;
	}

	.auth {
		min-width: 100%;
		width: 100%;
	}

	&__auth-layer {
		width: 100%;
		position: relative;
		z-index: 1;

		.auth-layer {
			display: flex;

			&__marketing {
				position: relative;
				flex: 1;
				text-align: left;
				display: flex;
				align-items: center;

				@include gridle_state(md) {
					padding: 0 0 0 24px;
					margin-right: 48px;
				}
			}

			&__auth-form {
				display: flex;
				flex-direction: column;
				background: $auth-form-background-static;
				box-shadow: $auth-form-box-shadow-static;
				border: 1px solid $auth-form-border-color-static;
				box-sizing: border-box;

				.auth__main {
					width: 320px;
					background: transparent;
					height: initial;
					padding: 32px 48px;
				}
			}
		}
	}

	&__hero-image {
		position: absolute;
		top: 0;
		right: 0;
		left: 0;
		bottom: 0;
		z-index: 1;
	}

	&__certifications {
		position: absolute;
		bottom: 0;
		right: 0;
		left: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		color: white;
		width: 100%;
		height: 50px;
		background-image: linear-gradient(to bottom, rgba(64, 64, 64, 0), rgba(28, 40, 53, 0.85));
		text-align: center;
		padding-bottom: 10px;
		z-index: 1;

		svg {
			fill: white;
			stroke: white;

			&:first-of-type {
				border-right: 1px solid white;
				padding-right: 10px;
				margin-right: 10px;
			}
		}
	}

	&__close-icon {
		position: absolute;
		right: -32px;
		top: -32px;
		cursor: pointer;

		@include gridle_state(md) {
			right: -60px;
			top: 0;
		}

		svg {
			width: 32px;
			height: 32px;

			@include gridle_state(md) {
				width: 40px;
				height: 40px;
			}
		}
	}

	&__marketing {
		margin: 0 auto;
		padding: 24px 0 32px;

		@include gridle_state(md) {
			margin: 0;
			padding: 0;
		}

		&-title {
			color: $auth-form-text-color-static;
			text-align: center;

			@include gridle_state(md) {
				text-align: left;
			}
		}

		&-description {
			font-size: 1.4rem;
			color: $auth-form-text-color-static;
			letter-spacing: -0.14px;
			margin-top: 16px;
			text-align: center;
			line-height: 1.2;

			@include gridle_state(md) {
				margin-top: 40px;
				font-size: 2rem;
				text-align: left;
			}
		}
	}

	.background-container,
	.background__radial-gradient {
		border-radius: $border-radius-big;
	}
}
