@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/gridle";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
.smartdp-marketing-headline {
	display: flex;
	flex-direction: column;
	color: white;
	text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
	line-height: 1;

	a {
		color: white;
	}

	em {
		font-style: italic;
	}

	&__title {
		line-height: 1;
	}

	&__subtitle {
		margin-top: 4px;
	}

	@include gridle_state(md) {
		width: 100%;
	}
}
