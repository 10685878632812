@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/gridle";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
$background-primary-gradient: linear-gradient(90deg, $color-primary-dark 0%, $color-primary 100%),
	linear-gradient(90deg, $color-primary 0%, $color-primary-light 100%) !default;
$chip-background-color: #bfddf4 !default;
$chip-text-color: #3493dc !default;
$menu-account-menu-item-color: $black !default;
$menu-account-submenu-item-color: $black !default;
$header-text-color: $black !default;
$icon-width: 56px;
$side-panel-header-height: 64px;

%account-menu-item-icon {
	color: $header-text-color;
	width: 20px;
	height: 20px;

	path {
		fill: $header-text-color;
	}
}

.account-menu {
	&__dropdown {
		z-index: 99;
	}

	&__modal {
		background: $header-background-color;
		overflow: hidden;

		@include gridle_state(md) {
			width: 375px;
			border-radius: $border-radius-big;
			box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
		}
	}

	&__section {
		&:not(:last-of-type) {
			border-bottom: 1px solid var(--color-gray-400, $grey-light);
		}
	}

	&__head {
		&-item {
			display: flex;
			align-items: center;
			padding: 18px 0 15px;

			.icon-account__wpr {
				@extend %account-menu-item-icon;
			}

			&--link:hover {
				background: darken($header-background-color, 5%);
			}
		}

		&-icon {
			display: flex;
			align-items: center;
			justify-content: center;
			width: $icon-width;

			svg {
				@extend %account-menu-item-icon;
			}
		}

		&-title {
			color: $menu-account-menu-item-color;
		}

		&-link {
			width: 100%;

			.relative-link__text {
				display: block;
				width: 100%;
			}
		}
	}

	&__item {
		justify-content: flex-start;
		padding: 18px $icon-width;
		height: 48px;
		cursor: pointer;
		box-sizing: border-box;

		&:hover {
			background: darken($header-background-color, 5%);
		}

		.relative-link__text {
			color: $menu-account-submenu-item-color;
			position: relative;

			.dot-notification {
				position: absolute;
				top: 1px;
				right: -8px;
				width: 7px;
				height: 7px;
			}
		}

		&--chip {
			.relative-link__text {
				display: flex;
				justify-content: space-between;
				width: 100%;
			}
		}
	}

	&__chip {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 8px 20px;
		height: 28px;
		box-sizing: border-box;
		border-radius: 44px;

		.amount {
			@extend %text-bold;

			font-size: 1.2rem;
			line-height: 1.4rem;
		}

		&--credit {
			color: white;
			background: $background-primary-gradient;
			min-width: 90px;

			.amount {
				color: white;
			}

			svg {
				margin-right: 6px;
			}
		}

		&--refund {
			color: $color-info;
			background: transparentize($color-info, 0.65);
			min-width: 90px;

			.amount {
				color: $color-info;
			}
		}
	}

	&__footer {
		border-top: 1px solid $grey-medium;
	}

	&__side-panel {
		width: 100vw;
		height: 100dvh;
		right: 0;
		background: $header-background-color;
		transform: translateX(100%);

		&.aside-panel--pushed {
			transform: translateX(0);
		}

		&-header {
			display: flex;
			align-items: center;
			justify-content: space-between;
			position: sticky;
			height: $side-panel-header-height;
			padding: 0 16px;
			box-sizing: border-box;
			border-bottom: 1px solid var(--color-header-bottom-border, $header-border-bottom);
			color: $header-text-color;
			overflow: hidden;

			.logo-brand__logo {
				height: $side-panel-header-height;
				max-width: 100%;
			}
		}

		&-content {
			height: calc(100% - $side-panel-header-height);
			overflow: auto;
		}

		&--eh {
			.logo-brand {
				height: 84px;
				transform: translateY(-10px);
			}
		}
	}

	.shop-switcher {
		color: $menu-account-menu-item-color;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding-right: 24px;
	}
}
