@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/gridle";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
.content-image-carousel {
	&__title {
		&-label {
			padding-bottom: 24px;
		}
	}

	&__description {
		padding-bottom: 24px;
		@include gridle_state(md) {
			padding-bottom: 64px;
		}
	}

	&__title,
	&__description {
		@include container;
	}

	&__articles {
		.media-article__description {
			margin-bottom: 0;
		}

		&-only {
			padding-top: 0;

			.media-carousel__caroussel {
				padding-top: 0;
			}
		}

		@include gridle_state(md) {
			padding-left: calc(((100vw - 1024px) / 2) - 2px);
		}
	}

	.line-clamp-view-more__reduce a {
		margin-left: 0;
	}
}
