@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/gridle";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
$listing-time-background-color: $badge-background-color !default;
$listing-time-border-radius: 10px !default;

.product-top-bar {
	display: flex;
	align-items: center;
	gap: 8px;
	padding: 0;

	.badge {
		background: var(--color-background-timer, $listing-time-background-color);
		color: var(--color-text-timer, white);
		border-radius: $listing-time-border-radius;
		padding-right: 8px;
		padding-left: 4px;
		height: 24px;
		box-sizing: border-box;
		font-size: var(--font-size-xsmall);

		@include gridle_state(md) {
			font-size: var(--font-size-small);
		}
	}

	&__badges {
		.colored-product-badge {
			height: 24px;
			box-sizing: border-box;
			background: white !important;
			border: none !important;
			color: $black !important;
			text-transform: none !important;
			padding-right: 8px;
			padding-left: 8px;
			font-size: var(--font-size-xsmall);

			@include gridle_state(md) {
				font-size: var(--font-size-small);
			}
		}
	}
}
