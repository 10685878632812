@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/gridle";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
.save-up-to {
	letter-spacing: 0.1rem;

	div {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	&__value {
		margin-left: 5px;
		font-size: 3rem;
	}

	&__unit {
		margin: -15px 3px 0 0;
	}
}
