@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/gridle";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
@import "~swiper/src/swiper";

$listings-product-width: 50% !default;
$listings-product-spacing: 10px !default;

.images-carousel-pp {
	width: 100%;
	height: 100%;
	display: flex;
	position: relative;

	.swiper-container {
		width: 100%;
		z-index: 0;
	}

	&__button--back,
	&__button--next {
		background-color: transparent;
		border: none;
	}

	&__arrow {
		position: absolute;
		width: 32px;
		height: 32px;
		top: 40%;
		z-index: 1;
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;

		&::before {
			content: " ";
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 32px;
			opacity: 0.7;
			z-index: -1;
			background: white;
		}

		&-left::before {
			border-radius: 0 3px 3px 0;
		}

		&-right::before {
			border-radius: 3px 0 0 3px;
		}

		@include gridle_state(md) {
			width: 40px;
			height: 40px;

			&::before {
				height: 40px;
			}
		}
	}

	&__arrow-left,
	&__arrow-right {
		@include gridle_state(md) {
			opacity: 0;
			transition: opacity 0.35s ease, transform 0.5s ease;
		}
	}

	&__arrow-left {
		left: 0;

		@include gridle_state(md) {
			transform: translateX(-50%);
		}
	}

	&__arrow-right {
		right: 0;

		@include gridle_state(md) {
			transform: translateX(50%);
		}
	}

	&__image {
		cursor: pointer;
		border-radius: $border-radius-big $border-radius-big 0 0;
	}

	.carousel-dots {
		position: absolute;
		bottom: 8px;
		left: 0;
		right: 0;
		margin: auto;

		@include gridle_state(md) {
			bottom: 16px;
		}
	}
}
