@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/gridle";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
.marketing-banner {
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	background-color: $grey-dark;
	color: white;
	padding: 10px;
	text-align: center;
	z-index: 6; // 6 to be above ek logo

	$this: &;

	.paragraphs {
		&__item {
			margin-bottom: 0;
		}
	}

	a {
		margin-left: 5px;
		color: inherit;
	}

	strong,
	a {
		white-space: nowrap;
		font-size: inherit;
	}

	&__link,
	&__link .relative-link__text {
		width: 100%;
	}

	&__link .relative-link__text {
		display: block; // full largeur
	}

	&__promocode {
		margin-right: 20px;
		border: 1px solid currentcolor;
		padding: 1px 10px;
		border-radius: $border-radius-small;
	}

	&__icon {
		display: block;
		width: 20px;
		height: 20px;

		@include gridle_state(md) {
			width: 24px;
			height: 24px;
		}
	}

	&--media {
		&-start {
			#{$this}__icon {
				order: -1;
				margin-right: 6px;
			}
		}

		&-end {
			#{$this}__icon {
				margin-left: 6px;
			}
		}
	}
}
