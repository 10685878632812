@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/gridle";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
.shop-switcher {
	cursor: pointer;

	&__content {
		display: flex;
		flex-direction: row;
		align-items: center;
		padding: 18px 0 15px;

		@include gridle_state(md) {
			flex-direction: column;
			align-items: center;
			padding: 0;
		}
	}

	&__button {
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: center;

		@include gridle_state(md) {
			margin-bottom: 6px;
			height: 32px;
		}

		.external-current-color-svg,
		.icon:first-of-type {
			width: 56px;
			height: 20px;

			@include gridle_state(md) {
				width: 28px;
				height: 28px;
			}
		}
	}
}
