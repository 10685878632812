:root {
	--color-primary-100: #d9e3f4;
	--color-primary-200: #b3c7e9;
	--color-primary-300: #6d9be8;
	--color-primary-400: #0053dc;
	--color-primary-500: #0045b6;
	--color-primary-600: #14438b;
	--color-primary-700: #051039;
	--color-primary-800: #00112a;

	--color-secondary-100: #f9dfdf;
	--color-secondary-200: #f4c0c0;
	--color-secondary-300: #e98281;
	--color-secondary-400: #f15857;
	--color-secondary-500: #d40504;
	--color-secondary-600: #ac0403;
	--color-secondary-700: #8b0303;
	--color-secondary-800: #5a0202;

	--color-black: #2b383f;
	--color-gray-300: #f7f7f9;
	--color-gray-400: #eff0f4;
	--color-gray-500: #d9d9d9;
	--color-gray-600: #727272;
	--color-gray-700: #565656;

	// timer
	--color-background-timer: var(--color-secondary-500);
	--color-text-timer: white;

	// footer
	--color-background-footer: var(--color-primary-700);
	--color-text-footer: white;

	// badge inclus
	--color-background-badge-inclus: var(--color-primary-700);
	--color-text-badge-inclus: white;

	// push notification
	--color-background-push-notification: var(--color-primary-600);

	// active color
	--color-active: var(--color-primary-500);
	--tab-active-color: var(--color-primary-700);

	// payment banner
	--payment-description-background-color: var(--color-primary-100);
	--payment-description-instalments-background-color: var(--color-primary-500);

	// choice
	--choice-background-color: var(--color-primary-700);

	// input-label
	--input-label-active-color: var(--color-primary-500);

	// picture shadow
	--picture-shadow-layer: rgba(
		5,
		16,
		57,
		0.4
	); // rgba(var(--color-primary-800), 0.3) ne fonctionne pas car CSS ne convertit pas directement
	// les couleurs hex en RGB. Utilisation manuelle de la valeur RGB équivalente.

	// membership bloc
	--account-bg-membership: radial-gradient(
			113% 70.63% at 61.25% 50%,
			#007bc4 0%,
			rgba(0, 92, 146, 0) 100%
		),
		#051039;
	// credit bloc
	--gradient-credit-color: linear-gradient(90deg, #051039 0%, #0045b6 100%);

	--color-text-loyalty: #0800ba;

	// active color
}
