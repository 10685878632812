@media only percy {
	.progressive-image,
	.background-progressive-image__image,
	.cookie-policy-footer {
		visibility: hidden !important;
	}

	.product-time__time,
	.loading-bar,
	iframe {
		display: none !important;
	}
}
