@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/gridle";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
@import "../../.styles/button";

.button--facebook {
	@extend %button--facebook;

	min-height: 39px; // unify with the google signin button height

	// Pour l'affichage du logo facebook
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	padding: 0 12px;
	text-transform: none;
	font-size: 1.4rem;

	.button__text {
		justify-content: space-between;
		width: 100%;

		div {
			flex-grow: 1;
			text-align: center;
		}
	}

	&.button--disabled {
		background: #4c69ba !important;
		border: 1px solid #4c69ba !important;
		color: white !important;
		cursor: not-allowed !important;
	}
}
