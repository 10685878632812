:root {
	// FONT-SIZE
	--font-size-xl10: 2.8rem;
	--font-size-xl9: 2.8rem;
	--font-size-xl8: 2.8rem;
	--font-size-xl7: 2.8rem;
	--font-size-xl6: 2.8rem;
	--font-size-xl5: 2.6rem;
	--font-size-xl4: 2.4rem;
	--font-size-xl3: 2.2rem;
	--font-size-xl2: 2rem;
	--font-size-xl: 1.8rem;
	--font-size-large: 1.6rem;
	--font-size-regular: 1.4rem;
	--font-size-small: 1.2rem;
	--font-size-xsmall: 1rem;
	--font-size-xxsmall: 0.8rem;

	--font-size-bold-xl10: 3.8rem;
	--font-size-bold-xl9: 3.8rem;
	--font-size-bold-xl8: 3.8rem;
	--font-size-bold-xl7: 3.8rem;
	--font-size-bold-xl6: 2.8rem;
	--font-size-bold-xl5: 2.6rem;
	--font-size-bold-xl4: 2.4rem;
	--font-size-bold-xl3: 2.2rem;
	--font-size-bold-xl2: 2rem;
	--font-size-bold-xl: 1.8rem;
	--font-size-bold-large: 1.6rem;
	--font-size-bold-regular: 1.4rem;
	--font-size-bold-small: 1.2rem;
	--font-size-bold-xsmall: 1rem;
	--font-size-bold-xxsmall: 0.8rem;

	// FONT-FAMILY
	--font-family-xl10: var(--font-family);
	--font-family-xl9: var(--font-family);
	--font-family-xl8: var(--font-family);
	--font-family-xl7: var(--font-family);
	--font-family-xl6: var(--font-family);
	--font-family-xl5: var(--font-family);
	--font-family-xl4: var(--font-family);
	--font-family-xl3: var(--font-family);
	--font-family-xl2: var(--font-family);
	--font-family-xl: var(--font-family);
	--font-family-large: var(--font-family);
	--font-family-regular: var(--font-family);
	--font-family-small: var(--font-family);
	--font-family-xsmall: var(--font-family);
	--font-family-xxsmall: var(--font-family);

	--font-family-bold-xl10: var(--font-family-bold);
	--font-family-bold-xl9: var(--font-family-bold);
	--font-family-bold-xl8: var(--font-family-bold);
	--font-family-bold-xl7: var(--font-family-bold);
	--font-family-bold-xl6: var(--font-family-bold);
	--font-family-bold-xl5: var(--font-family-bold);
	--font-family-bold-xl4: var(--font-family-bold);
	--font-family-bold-xl3: var(--font-family-bold);
	--font-family-bold-xl2: var(--font-family-bold);
	--font-family-bold-xl: var(--font-family-bold);
	--font-family-bold-large: var(--font-family-bold);
	--font-family-bold-regular: var(--font-family-bold);
	--font-family-bold-small: var(--font-family-bold);
	--font-family-bold-xsmall: var(--font-family-bold);
	--font-family-bold-xxsmall: var(--font-family-bold);

	// FONT-WEIGHT
	--font-weight-bold-xl10: normal;
	--font-weight-bold-xl9: normal;
	--font-weight-bold-xl8: normal;
	--font-weight-bold-xl7: normal;
	--font-weight-bold-xl6: normal;
	--font-weight-bold-xl5: normal;
	--font-weight-bold-xl4: normal;
	--font-weight-bold-xl3: normal;
	--font-weight-bold-xl2: normal;
	--font-weight-bold-xl: normal;
	--font-weight-bold-large: normal;
	--font-weight-bold-regular: normal;
	--font-weight-bold-small: normal;
	--font-weight-bold-xsmall: normal;
	--font-weight-bold-xxsmall: normal;

	// TEXT-TRANSFORM
	--text-transform-xl10: initial;
	--text-transform-xl9: initial;
	--text-transform-xl8: initial;
	--text-transform-xl7: initial;
	--text-transform-xl6: initial;
	--text-transform-xl5: initial;
	--text-transform-xl4: initial;
	--text-transform-xl3: initial;
	--text-transform-xl2: initial;
	--text-transform-xl: initial;
	--text-transform-large: initial;
	--text-transform-regular: initial;
	--text-transform-small: initial;
	--text-transform-xsmall: initial;
	--text-transform-xxsmall: initial;

	--text-transform-bold-xl10: initial;
	--text-transform-bold-xl9: initial;
	--text-transform-bold-xl8: initial;
	--text-transform-bold-xl7: initial;
	--text-transform-bold-xl6: initial;
	--text-transform-bold-xl5: initial;
	--text-transform-bold-xl4: initial;
	--text-transform-bold-xl3: initial;
	--text-transform-bold-xl2: initial;
	--text-transform-bold-xl: initial;
	--text-transform-bold-large: initial;
	--text-transform-bold-regular: initial;
	--text-transform-bold-small: initial;
	--text-transform-bold-xsmall: initial;
	--text-transform-bold-xxsmall: initial;
}

@include gridle_state(md) {
	:root {
		--font-size-xl10: 8rem;
		--font-size-xl9: 6.4rem;
		--font-size-xl8: 5.2rem;
		--font-size-xl7: 4.4rem;
		--font-size-xl6: 3.6rem;
		--font-size-xl5: 2.8rem;
		--font-size-xl4: 2.4rem;
		--font-size-xl3: 2.2rem;
		--font-size-xl2: 2rem;
		--font-size-xl: 1.8rem;
		--font-size-large: 1.6rem;
		--font-size-regular: 1.4rem;
		--font-size-small: 1.2rem;
		--font-size-xsmall: 1rem;
		--font-size-xxsmall: 0.8rem;

		--font-size-bold-xl10: 8rem;
		--font-size-bold-xl9: 6.4rem;
		--font-size-bold-xl8: 5.2rem;
		--font-size-bold-xl7: 4.4rem;
		--font-size-bold-xl6: 3.6rem;
		--font-size-bold-xl5: 2.8rem;
		--font-size-bold-xl4: 2.4rem;
		--font-size-bold-xl3: 2.2rem;
		--font-size-bold-xl2: 2rem;
		--font-size-bold-xl: 1.8rem;
		--font-size-bold-large: 1.6rem;
		--font-size-bold-regular: 1.4rem;
		--font-size-bold-small: 1.2rem;
		--font-size-bold-xsmall: 1rem;
		--font-size-bold-xxsmall: 0.8rem;
	}
}
